.main-layout-wrapper {
  @apply flex flex-col;

  .nav {
    @apply bg-white px-8 py-2 z-0;
    box-shadow: 0px 3px 10px #00000029 !important;
    color: #000 !important;

    .nav-wrapper {
      @apply flex items-center justify-between;

      .logo-wrapper {
        @apply h-14 w-36;

        .logo {
          @apply w-full h-full object-contain;
        }
      }

      .active {
        @apply text-primary;
      }

      .nav-menu-item {
        @apply flex flex-col items-center justify-center px-4;


        .nav-menu-item-icon {
          // fill: black;
          // fill: white;
          opacity: 1;
        }

        .nav-menu-item-text {
          @apply pt-1;
        }
      }

      .actions-wrapper {
        @apply flex items-center justify-between;
      }

    }
  }

  .page-wrapper {
    @apply flex overflow-y-auto;
    height: calc(100vh - 72px);

    .side-menu {
      @apply bg-primary w-14 transition-all duration-300 absolute z-20;
      height: inherit;

      .side-menu-wrapper {
        .side-menu-items {
          @apply px-1;

          .side-menu-item {
            @apply flex flex-col items-center justify-center py-6 border-b border-gray-700 border-opacity-10;

            .side-menu-item-icon {
              @apply transition-all duration-300;
              fill: black;
              // fill: white;
              opacity: 0.6;
            }

            .side-menu-item-text {
              @apply text-white pt-1;
              display: none;
            }
          }
        }
      }
    }

    .content-wrapper {
      @apply w-full;
    }

  }

}

.is-auth-page {
  .nav {
    display: none;
  }

  .page-wrapper {
    height: auto;

    .side-menu {
      display: none;
    }

    .content-wrapper {
      @apply w-full pl-0;
    }
  }
}

@media (max-width: 1023px) {}

@media (max-width: 549px) {
  .main-layout-wrapper {
    .nav {
      @apply p-0;

      .nav-wrapper {
        .menu-icon {
          @apply bg-primary p-3 mr-2;
          border-radius: 0% 40% 40% 0%;

          svg {
            fill: #fff;
          }
        }

        .logo-wrapper {
          @apply h-8 w-24 my-1;
        }
      }
    }

    .page-wrapper {
      height: calc(100vh - 59px);

      .side-menu {
        @apply w-0 overflow-hidden;
        border-radius: 0% 30px 30px 0%;

        .side-menu-wrapper {
          .side-menu-items {
            .side-menu-item {
              .side-menu-item-icon {
                opacity: 1;
              }

              .side-menu-item-text {
                display: block;
              }
            }
          }
        }
      }

      .is-side-menu-open {
        @apply w-56;

        .side-menu-wrapper {
          .side-menu-items {
            .side-menu-item {
              .side-menu-item-icon {
                opacity: 1;
              }

              .side-menu-item-text {
                display: block;
              }
            }
          }
        }
      }

      .content-wrapper {
        @apply pl-0;
      }
    }

  }
}

@media (max-width: 375px) {}

@media (min-width: 550px) and (max-width: 640px) {}

@media (min-width: 641px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1279px) {}

@media (min-width: 1280px) and (max-width: 1536px) {}

@media (min-width: 550px) {
  .main-layout-wrapper {
    .page-wrapper {
      .side-menu {
        &:hover {
          @apply w-32 transition-all duration-300;

          .side-menu-wrapper {
            .side-menu-items {
              .side-menu-item {
                &:hover {
                  .side-menu-item-icon {
                    @apply transition-all duration-300;
                    opacity: 1;
                  }

                  .side-menu-item-text {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}