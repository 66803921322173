/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../src/assets/fonts.scss';

// @layer base {
//   html {
//     -webkit-text-stroke-width: 1px;
//     -webkit-text-stroke-color: red;
//   }
// }