/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v23-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v23-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v23-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v23-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v23-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v23-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v23-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v23-latin-500.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v23-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v23-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v23-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v23-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v23-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v23-latin-700.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

// /* chilanka-regular - latin */
// @font-face {
//   font-family: 'Chilanka';
//   font-style: normal;
//   font-weight: 400;
//   src: url('./fonts/chilanka-v16-latin-regular.eot');
//   /* IE9 Compat Modes */
//   src: local(''),
//     url('./fonts/chilanka-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */
//     url('./fonts/chilanka-v16-latin-regular.woff2') format('woff2'),
//     /* Super Modern Browsers */
//     url('./fonts/chilanka-v16-latin-regular.woff') format('woff'),
//     /* Modern Browsers */
//     url('./fonts/chilanka-v16-latin-regular.ttf') format('truetype'),
//     /* Safari, Android, iOS */
//     url('./fonts/chilanka-v16-latin-regular.svg#Chilanka') format('svg');
//   /* Legacy iOS */
// }

//normal
@font-face {
  font-family: 'Swissra';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  src: url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.eot");
  /* IE9*/
  src: url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.woff2") format("woff2"),
    /* chrome、firefox */
    url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.woff") format("woff"),
    /* chrome、firefox */
    url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./fonts/9f6b0d6d534e53c6e6d6517eb4e33cdf.svg#Swissra Normal") format("svg");
  /* iOS 4.1- */
}

//medium
@font-face {
  font-family: 'Swissra';
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  src: url("./fonts/6b58c5677ee24ec79be32379197a99e4.eot");
  /* IE9*/
  src: url("./fonts/6b58c5677ee24ec79be32379197a99e4.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./fonts/6b58c5677ee24ec79be32379197a99e4.woff2") format("woff2"),
    /* chrome、firefox */
    url("./fonts/6b58c5677ee24ec79be32379197a99e4.woff") format("woff"),
    /* chrome、firefox */
    url("./fonts/6b58c5677ee24ec79be32379197a99e4.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./fonts/6b58c5677ee24ec79be32379197a99e4.svg#Swissra Medium") format("svg");
  /* iOS 4.1- */
}

//bold
@font-face {
  font-family: 'Swissra';
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  src: url("./fonts/66759116a9e3966edd71ea4886edcff0.eot");
  /* IE9*/
  src: url("./fonts/66759116a9e3966edd71ea4886edcff0.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./fonts/66759116a9e3966edd71ea4886edcff0.woff2") format("woff2"),
    /* chrome、firefox */
    url("./fonts/66759116a9e3966edd71ea4886edcff0.woff") format("woff"),
    /* chrome、firefox */
    url("./fonts/66759116a9e3966edd71ea4886edcff0.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./fonts/66759116a9e3966edd71ea4886edcff0.svg#Swissra Bold") format("svg");
  /* iOS 4.1- */
}