.patients-search-bar-container {
  @apply p-0 flex items-center;
  width: fit-content;

  .patients-search-bar-input {
    @apply mx-2 w-96;
  }

  .patients-search-bar-select {
    .MuiSelect-select {
      @apply py-1 w-20;
    }

    fieldset,
    input {
      @apply hidden;
    }
  }
}

.patients-search-bar-button {
  @apply shadow-none;
  transform: translateX(1px);
  height: 36.5px;
}

label {
  @apply text-black;
  top: -8px !important;
}

.MuiInputLabel-shrink {
  top: 0 !important;
}

.filter-select-drop-down {
  max-height: 200px !important;
}

@media (max-width: 1023px) {
  .patients-search-bar-container {
    .patients-search-bar-input {
      @apply w-72;
    }
  }
}

@media (max-width: 767px) {
  .patients-search-bar-container {
    @apply p-0;

    .patients-search-bar-input {
      @apply w-40;
    }
  }
}

@media (max-width: 639px) {
  .patients-search-bar-container {
    @apply p-0;

    .patients-search-bar-input {
      @apply mx-2 w-60;
    }

    .patients-search-bar-select {
      .MuiSelect-select {
        @apply py-1 w-20;
      }
    }
  }
}

@media (max-width: 549px) {
  .patients-search-bar-container {
    @apply p-0;

    .patients-search-bar-input {
      @apply mx-2 w-32;
    }

    .patients-search-bar-select {
      .MuiSelect-select {
        @apply py-1 w-20;
      }
    }
  }
}

@media (max-width: 375px) {
  .patients-search-bar-container {
    @apply p-0;

    .patients-search-bar-input {
      @apply mx-1 w-32;
    }

    .patients-search-bar-select {
      .MuiSelect-select {
        @apply py-1 w-20;
      }
    }
  }
}