.patients-table-container {
  @apply shadow-none pb-10 bg-white;
  height: calc(100vh - 233px);
  // max-height: calc(100vh - 202px);

  .patients-table {
    .patients-table-head {
      .patients-table-head-row {
        th {
          @apply border-gray-900 text-gray-900;
        }
      }
    }

    .patients-table-body {
      .patients-table-body-row {
        td {
          @apply text-opacity-100 font-bold;
        }

        hr {
          @apply w-7 mx-auto text-third opacity-25;
        }
      }
    }
  }
}

.procedures-categories-page-wrapper {
  margin-top: 0;

  .patients-table-container {
    height: calc(100vh - 74px);

    .procedures-category {
      .input-box {
        @apply my-0;

        div {
          @apply w-full;

          input {
            // @apply py-2;
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }


        .end-clinic-adornment {
          width: fit-content !important;
          // height: 36px;
          // @apply px-2 border-l border-secondary h-8 text-2xl;

          // p {
          //   @apply text-primary;
          // }
        }
      }

    }
  }
}