.auth-wrapper {
  @apply py-8 text-center bg-white rounded-lg flex flex-col items-center w-7/12;

  .logo {
    @apply mb-4 flex flex-col justify-center items-center;
  }

  .auth-form-title {
    @apply text-center text-2xl font-bold text-primary pb-16;
  }

  .auth-form {
    @apply w-9/12;

    .input-box {
      @apply w-full my-6 block text-left;

      input {
        @apply py-3;

        &::placeholder {
          @apply text-secondary opacity-100;
        }
      }

      &:first-child {
        @apply mt-0;
      }

      &:last-child {
        @apply mb-2;
      }

      div {
        @apply w-full;
      }

      .end-clinic-adornment {
        width: fit-content;
        height: 36px;
        @apply px-2 border-l border-secondary h-8 text-2xl;

        p {
          @apply text-primary;
        }
      }

      .start-clinic-adornment {
        width: fit-content;
        @apply h-8 text-2xl;

        p {
          @apply text-primary;
        }
      }
    }

    .phone-field-box {
      .react-tel-input {
        .special-label {
          left: 10px;
          @apply text-primary;
        }

        .form-control {
          @apply py-3 w-full border-primary;

          &:hover,
          &:focus {
            @apply border-primary shadow-none;
          }

          &::placeholder {
            opacity: 0;
          }
        }
      }
    }

    .password-box {
      @apply mb-2;

      div {
        div {
          div {
            @apply justify-end;
            width: fit-content;
          }
        }
      }
    }

    .button-wrapper {
      @apply flex flex-col items-center;

      .button {
        @apply w-9/12 py-3;
      }
    }
  }
}


@media (max-width: 767px) {
  .auth-wrapper {
    @apply w-10/12 px-4;

    .auth-form {
      @apply w-full;
    }
  }
}

@media (max-width: 639px) {
  .auth-wrapper {
    .logo {
      img {
        @apply w-9/12;
      }
    }
  }
}

@media (max-width: 549px) {
  .auth-wrapper {
    @apply w-full px-4;

    .auth-form {
      @apply w-full;

      .input-box {
        .end-clinic-adornment {
          p {
            font-size: 8px;
          }
        }
      }

      .button-wrapper {
        .button {
          @apply w-full;
        }
      }
    }
  }

}

@media (max-width: 375px) {
  .auth-wrapper {
    @apply px-2;
  }
}

@media (min-width: 550px) and (max-width: 640px) {}

@media (min-width: 641px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {
  .auth-wrapper {
    @apply w-10/12;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {}

@media (min-width: 1280px) and (max-width: 1536px) {}