.auth-page-wrapper {
  height: calc(100vh - 72px);
  @apply bg-gray-700 flex flex-col items-center justify-center;

  .auth-page-container {
    @apply container mx-auto flex flex-col items-center justify-center h-full;

    .auth-form-wrapper {
      @apply w-full flex flex-col items-center;
    }
  }
}

@media (max-width: 1023px) {
  .auth-page-wrapper {
    background: none;
    @apply bg-gray-700;
  }
}

@media (max-width: 549px) {
  .auth-page-wrapper {
    min-height: 100vh;
    height: auto;
  }
}

@media (max-width: 375px) {}

@media (min-width: 550px) and (max-width: 640px) {}

@media (min-width: 641px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1279px) {}

@media (min-width: 1280px) and (max-width: 1536px) {}