.wrapper {
  @apply bg-gray-700;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #009688;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary bg-opacity-40;
  // background-color: darkgrey;
}

.react-custom-beacon {
  width: 31px !important;
  height: 31px !important;
  cursor: help !important;
  border: 2px solid #27BDBE;
  border-radius: 50%;
  background-color: rgba(39, 189, 190, 0.2);
  display: flex;

  span {
    background-image: url(./assets/images/help.svg);
    background-size: 25px 25px;
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: auto;
  }
}