.product-form {

  .inputs-container {
    @apply flex gap-2 mb-2 flex-wrap;

    .input-box {
      @apply w-full;

      label {
        @apply -top-2 text-black;
      }

      .MuiInputLabel-shrink {
        @apply top-0;
      }

      .date-picker {

        .MuiInputAdornment-root {
          @apply justify-end;
        }
      }

      .uploader-autocomplete {
        div {
          div {
            @apply py-0;

            .MuiAutocomplete-endAdornment {
              @apply flex justify-end;
            }
          }
        }
      }

      div {
        @apply w-full;

        input {
          @apply py-2;
        }
      }
    }
  }
}